@media only screen and (max-width: 900px) {
    .not_to_display_in_phone{
        display:none!important;
    }
    .main_header_section_header_inner_header {
        padding: 0px 8px!important;
    }
    .main_header_section_header_inner_header .logo_section {
        font-size: 23px!important;
        font-weight: unset!important;
    }
    .main_header_section_header_inner_header .signin_button_section {
        padding: 7px 10px!important;
        font-size: 12px!important;
    }
    .main_header_section_header_inner_header .signin_button_section.admin{
        padding: 7px 10px!important;
        font-size: 12px!important;
    }
    .main_header_section_second_header {
        padding: 0px 13px!important;
    }
    .promotion_header_text_section .loan_text_span_1 {
        font-size: 15px!important;
    }
    .promotion_header_text_section .loan_text_span_2 {
        font-size: 14px!important;
    }
    .promotion_header_text_section span.loan_text_span_3 {
        font-size: 12px!important;
    }
    .loan_application_data_table {
        font-size: 17px;
    }
    .loan_application_data_main_section {
        padding: 25px!important;
    }
    .promotion_header_text_section .apply_loan_button {
        padding: 9px 21px!important;
        font-size: 12px!important;
        margin-top: 11px!important;
        margin-bottom: 10px!important;
    }

    .main_header_section_header img.site_logo_image {
        max-width: 44px!important;
        max-height: 35px!important;
        margin-right: 5px!important;
    }
    .home_page_main_content_section {
        padding: 14px!important;
    }
    .inner_section_block_card_loop_list .svg_icon svg {
        width: 40px!important;
    }
    .inner_section_block_card_loop_list .svg_icon {
        height: 64px!important;
        border-bottom: none!important;
    }
    .inner_section_block_card_loop_list .footer_text_section {
        padding: 2px 8px 0!important;
        font-size: 12px!important;
        text-transform: capitalize!important;
        font-weight: 400!important;
        line-height: 16px!important;
    }
    .footer_text_section_sub_text {
        display: none!important;
    }
    .inner_section_block_heading {
        font-size: 11px!important;
        padding-right: 44px!important;
        border-bottom: 1px solid var(--theme-color-first)!important;
    }
    .inner_section_block_card_loop_list {
        border: none!important;
        box-shadow: none!important;
        height: auto!important;
    }
    .footer_text_section .footer_text_2 {
        font-size: 13px!important;
        color: #5d6a77!important;
    }
    .footer_section_second_header{
        width: 100%!important;
    }
    .footer_text_section .footer_text_1 {
        font-size: 12px!important;
    }
    .download_app_data_section_div {
        padding: 0px 11px!important;
    }
    .download_app_data_section_div .download_app_data_section_div_inner_block .download_app_data_heading {
        font-size: 20px!important;
    }
    .download_app_data_section_div_inner_block ul.download_app_ul li {
        font-size: 14px!important;
    }
    .download_app_data_section_div_inner_block ul.download_app_ul {
        padding: 0px!important;
    }
    .inner_section_block_card_loop_list {
        background-color: unset!important;
    }
    .main_header_section_second_header.loan_page_application {
        padding: 20px
    }
    .main_header_section_second_header.loan_page_application .application_header_heading {
        font-size: 20px!important;
        margin-bottom: 36px!important;
    }
    .promotion_header_text_section_loan_page_form {
        font-size: 13px!important;
    }
    .promotion_header_text_section_loan_page_form input {
        font-size: 13px!important;
    }
    .promotion_header_text_section_loan_page_form button {
        font-size: 13px!important;
    }
    .main_header_section_second_header.loan_page_application img.promotion_add_header_section_img_1 {
        width: 100%!important;
        padding-bottom: 20px!important;
    }
    .promotion_header_text_section_loan_page_form select {
        font-size: 13px!important;
        color:#666666!important;
        height: 36px!important;
    }
    .promotion_header_text_section_loan_page_form .submit_form_button button.btn {
        font-size: 13px!important;
        padding: 10px 16px!important;
    }
    .select2-container--default .select2-selection--single .select2-selection__rendered{
        font-size: 13px!important;
        color:#666666!important;
        height: 36px!important;
    }
    .date_of_birth{
        display: block!important;
        height:34px!important;
    }
    .main_header_section_header.sub_header_section .main_header_section_header_inner_header {
        padding: 0px 0px!important;
    }
    .right_side_sub_header {
        margin-right: 28px!important;
    }
    .right_side_sub_header .back_button svg {
        width: 22px!important;
    }
    .right_side_sub_header .back_button {
        font-size: 12px!important;
    }
    #show_success_popup_main_section {
        padding: 17px;
    }
    .success_popup_inner_div .success_popup_header {
        padding: 10px;
        font-size: 14px;
    }
    .success_popup_icon_section_success img {
        width: 70px;
    }
    .success_popup_message_section {
        font-size: 15px;
    }
    .success_popup_body_message_section {
        font-size: 13px;
    }
    .success_popup_icon_section_loading img {
        width: 70px;
    }
    .success_popup_main_body .close_popup_button {
        padding: 6px 14px;
    }
    .login_page_right_side_container .login_title_section {
        font-size: 1rem;
    }
    .login_title_section_mobile_number_image img {
        max-width: 50%;
        padding: 25px;
    }
    .login_page_right_side_container .login_title_section_input_mobile {
        padding: 11px 50px;
    }
    .login_title_section_input_mobile_button .login_button {
        padding: 14px 100px;
        font-size: 15px;
    }
    .main_header_section_header_inner_header .user_login_avatar_icon {
        width: 30px;
        height: 30px;
    }
    .main_header_section_header_inner_header .user_login_avatar_icon svg {
        width: 20px;
    }
    .main_header_section_header_inner_header .dropdown-menu {
        font-size: 13px;
        right: 10px;
    }
    .not_to_display_in_desktop{
        display: block!important;
    }
    .loan_calculator_page_container_left_side_inner_section {
        height: auto!important;
        padding: 20px 20px;
    }
    .left_side_footer_graph_section {
        display: none!important;
    }
    .loan_calculator_page_container_right_side_section {
        padding: 14px!important;
        height: auto!important;
    }
    .loan_calculator_page_container_right_side_section_inner {
        margin-top: 0px!important;
    }
    .loan_calculator_page_container_right_side_section_inner .loan_type_button {
        font-size: 12px!important;
        font-weight: 0!important;
        height: 58px!important;
    }
    .loan_calculator_page_container_right_side_section_inner .loan_calculator_input_label {
        font-size: 14px;
    }
    .loan_calculator_page_container_right_side_section_inner .loan_calculator_input_text {
        font-size: 16px;
    }
    .loan_calculator_input_text_slider .grid__item--range-slider {
        height: 10px;
    }
    .loan_calculator_input_text_slider .slider_amount_range {
        font-size: 10px;
    }
    .loan_calculator_right_side_heading_mobile .right_side_sub_header .back_button svg {
        fill: #ffffff!important;
    }
    .loan_calculator_right_side_heading_mobile .right_side_sub_header {
        color: #ffffff!important;
    }
    .credit_score_main_section .main_header_heading {
        font-size: 18px;
    }
    .credit_score_main_section .sub_header_heading {
        font-size: 14px;
    }
    .credit_score_main_section #arc #currentScoreContainer {
        left: 75px;
    }
    .credit_score_main_section #arc #currentScoreContainer #currentScore {
        font-size: 33px;
    }
    .credit_score_main_section #dot {
        margin: 1px 4px;
    }
    .credit_report_date_section span.date {
        font-size: 14px;
    }
    .credit_report_date_section .credit_report_section {
        font-size: 14px;
    }
    img.equifax_logo_section {
        width: 87px;
    }
    .credit_score_tab_section_user_info_summery {
        padding: 0px 21px;
    }
    .credit_score_summary_card_header .svg_icon svg {
        width: 50px;
    }
    .credit_score_summary_card_header span.heading_main_text {
        font-size: 14px;
    }
    .credit_score_summary_card_header span.heading_sub_text {
        font-size: 13px;
    }
    .credit_score_summary_card_header .main_heading {
        width: 56%;
    }
    .credit_score_summary_card_header .impact_div {
        font-size: 11px;
    }
    .credit_score_summary_card_header .main_heading {
        padding: 0px 9px;
    }
    .credit_score_card_row {
        font-size: 13px;
    }
    .credit_score_summary_card_header .impact_div {
        padding: 2px;
    }
    .loan_banks_list_data_loop img.bank_image_section {
        margin-bottom: 7px;
        padding: 12px;
        width: 143px;
        height: 60px;
    }
    .bank_name_section {
        font-size: 12px;
    }
    .bank_interest_rate {
        font-size: 13px;
    }
    .bank_main_list_section {
        height: calc(100vh - 136px)!important;
    }

}